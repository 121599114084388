.has-box-shadow{
    box-shadow: 2px 2px 10px 4px rgba(0,0,0,0.75);
    -webkit-box-shadow: 2px 2px 10px 4px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 2px 10px 4px rgba(0,0,0,0.75);
}

#main_logo {
    max-height: unset;
    height: 2rem;
}

.hero {
    background-image: url(../images/pexels-manuel-geissinger-325229.jpg?as=webp); //url(../images/hero.jpg?as=webp);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    @media only screen and (max-width: 500px) {
    background-position: left;
    }
}

.section {
    min-height: 100vh;
}

#top_btn {
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
}

.card {
    min-height: 600px;
}